import React from 'react'
import SEO from '../../components/seo'
import { useTranslations } from '../../hooks/use-translations'
import NewsSection from '../../components/NewsSection'

import * as s from '../news.module.scss'

const ExportIfcPage: React.FC = () => {
  const { t } = useTranslations()
  const title = t('Export IFC')
  const description = t('export_ifc_tools_description')
  const content = t('export_ifc_tools_content')

  return (
    <>
      <SEO title={title} description={description} />
      <NewsSection title={title} rayContent maxContent classTitle="pt-5">
        <p className={s.text}>{content}</p>
      </NewsSection>
    </>
  )
}

export default ExportIfcPage
